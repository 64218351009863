<template>
  <el-card v-loading.body="isLoading" shadow="never">
    <template #header>
      <auth-card-header :heading="$t('page_set_password.form_title')"/>
    </template>
    <el-form
        :rules="formRules"
        size="medium"
        status-icon
        :model="form"
        ref="form"
    >
      <el-form-item prop="password">
        <el-input :placeholder="$t('forms.password')" v-model="form.password" type="password"/>
      </el-form-item>
      <v-alert ref="alert"/>
      <el-form-item>
        <v-wide-button size="large" type="primary" @click.native.prevent="handleSetPassword">
          {{ $t('page_set_password.btn_submit') }}
        </v-wide-button>
      </el-form-item>
      <v-alert ref="alert"/>
    </el-form>
  </el-card>
</template>

<script>
import * as backgroundImage from '../../../assets/images/bg-reset.jpg'

import VWideButton from '../../components/ui/v-wide-button/VWideButton'
import VAlert from '../../components/ui/v-alert/VAlert'

import {provideNewPassword} from '../shared/services/auth'
import {passwordValidatorRules} from '../../shared/validators/passwordValidatorRules'

import {mapState} from 'vuex'
import AuthCardHeader from '../shared/components/auth-card-header/AuthCardHeader'

export default {
  name: 'AuthSetNewPassword',
  components: {
    AuthCardHeader,
    VWideButton,
    VAlert
  },
  inject: ['changeLayoutImage'],
  created() {
    this.changeLayoutImage(backgroundImage)
  },
  data() {
    return {
      formRules: {
        password: passwordValidatorRules
      },
      form: {
        password: ''
      }
    }
  },
  computed: {
    ...mapState('loadingState', {
      isLoading: state => state.isLoading
    }),
  },
  methods: {
    handleSetPassword() {
      const {form, $route, $refs} = this
      const DEFAULT_TIMEOUT = 3000
      $refs.form.validate((valid) => {
        if (!valid) return
        const payload = {
          ...form,
          token: $route.params.token
        }
        provideNewPassword(payload)
            .then(() => {
              $refs.alert.showAlert({
                type: 'success',
                timeout: DEFAULT_TIMEOUT,
                text: this.$t('alerts.new_password_set')
              })
              setTimeout(() => {
                this.$router.push({name: 'login'})
              }, DEFAULT_TIMEOUT)
            })
            .catch((e) => {
              $refs.alert.showAlert({
                type: 'error',
                timeout: DEFAULT_TIMEOUT,
                text: this.$t(`alerts.invalid_token`)
              })
            })
      })
    }
  }
}
</script>
